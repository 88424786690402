export const defaultFailedResponse = {
    code: 'failed',
    errors: 'Something went wrong',
    data: {}
};

const droomFetch = (path, storeData = false, fetchOptions = {}) => {
    let url = '';
    if (path.split('://')?.[1]) {
        url = path;
    } else {
        url = `/${path.replace(/^\//, '')}`;
    }

    return new Promise((resolve, reject) => {
        if (storeData && sessionStorage.getItem(`apiFetch[${url}]`)) {
            resolve(JSON.parse(sessionStorage.getItem(`apiFetch[${url}]`)));
        } else {
            fetch(url, fetchOptions)
                .then(response =>
                    response
                        .json()
                        .catch(
                            /* added in cases where response is not json and dot json call fails */ () =>
                                defaultFailedResponse
                        )
                )
                .then(res => {
                    if (res.error_code !== undefined && res.error_code === 401) {
                        window.location.replace(res.redirect);
                    }
                    if (Storage && storeData) {
                        sessionStorage.setItem(`apiFetch[${url}]`, JSON.stringify(res));
                    }
                    resolve(res);
                })
                .catch(error => {
                    reject(error);
                });
        }
    });
};

export const PostData = (type, userData, storeData, hasFile) => {
    let fetchOptions = {
        method: 'POST',
        credentials: 'include',
        body: userData
    };

    if (!hasFile) {
        const headers = {
            'Content-Type': 'application/json',
            'X-Request-Fetch': 1,
            'X-Requested-With': 'XMLHttpRequest'
        };
        fetchOptions = {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(userData),
            headers: new Headers(headers)
        };
    }
    return droomFetch(type, !!storeData, fetchOptions);
};

export const GetData = (path, userData = {}, storeData = false) => {
    const headers = {
        'Content-Type': 'application/json',
        'X-Request-Fetch': 1,
        'X-Requested-With': 'XMLHttpRequest'
    };

    const fetchOptions = {
        method: 'get',
        headers: new Headers(headers)
    };

    let pathWithParam = path;

    if (userData && Object.keys(userData).length !== 0) {
        pathWithParam += '?';
        pathWithParam += new URLSearchParams(userData).toString();
    }

    return droomFetch(pathWithParam, storeData, fetchOptions);
};
