import React from 'react';
import loadable from '@loadable/component';
import Loader from '@components/Loader';

export default loadable(
    () => import(/* webpackChunkName: "Valuation" */ `./Container`),
    {
        fallback: <Loader />
    }
);
