import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FrontloadProvider } from 'react-frontload';
import { TranslationsProvider } from '@eo-locale/react';

import Router from '@app/routes';
import { isDev, LocaleConfig, getLocaleFromPath } from '@config/index';

const localeErrorLogger = error => {
    // eslint-disable-next-line no-console
    if (isDev) console.error(error);
};

const App = ({ frontloadState, reduxStore }) => {
    const location = useLocation();

    const [locale, setLocale] = React.useState(LocaleConfig.fallbackLocale);

    React.useEffect(() => {
        const { locale: newLocale } = getLocaleFromPath(location.pathname);
        setLocale(newLocale);
    }, [location.pathname]);

    return (
        <React.StrictMode>
            <Provider store={reduxStore}>
                <FrontloadProvider initialState={frontloadState}>
                    <TranslationsProvider
                        language={locale}
                        locales={LocaleConfig.messages}
                        // Disabling missing key errors for production
                        onError={localeErrorLogger}
                    >
                        <Router />
                    </TranslationsProvider>
                </FrontloadProvider>
            </Provider>
        </React.StrictMode>
    );
};

App.propTypes = {
    frontloadState: PropTypes.shape({
        setFirstRenderDoneOnClient: PropTypes.func.isRequired,
        isFirstRender: PropTypes.func.isRequired,
        isClientLoggingEnabled: PropTypes.func.isRequired,
        prepareServerRenderPass: PropTypes.func.isRequired,
        collectServerRenderFrontload: PropTypes.func.isRequired,
        hasFrontloadRunOnServer: PropTypes.func.isRequired,
        getFrontloadServerRenderedData: PropTypes.func.isRequired,
        getServerRenderMeta: PropTypes.func.isRequired,
        getServerRenderCache: PropTypes.func.isRequired
    }).isRequired,
    reduxStore: PropTypes.shape({
        subscribe: PropTypes.func.isRequired,
        dispatch: PropTypes.func.isRequired,
        getState: PropTypes.func.isRequired
    }).isRequired
};

export default App;
