import { DEVICE, COMPUTER_MIN_WIDTH, ORIENTATION } from '@components/constants';
import { SET_DEVICE, SET_WIDTH, SET_ORIENTATION } from './types';

const initialState = {
    device: DEVICE.COMPUTER,
    width: COMPUTER_MIN_WIDTH,
    orientation: ORIENTATION.DESKTOP
};

export default (state = initialState, { type, payload } = {}) => {
    switch (type) {
        case SET_DEVICE:
            return {
                ...state,
                device: payload
            };

        case SET_WIDTH:
            return {
                ...state,
                width: payload,
                device:
                    payload < COMPUTER_MIN_WIDTH
                        ? DEVICE.MOBILE
                        : DEVICE.COMPUTER
            };

        case SET_ORIENTATION:
            return {
                ...state,
                orientation: payload
            };

        default:
            return state;
    }
};
