import React from 'react';
import PropTypes from 'prop-types';

import './Pagination.scss';

const Pagination = ({ currentPage, numPages, size, handlePageChange }) => {
    const [state, setState] = React.useState({
        activeInit: 1,
        active: parseInt(currentPage, 10)
    });

    const reset = pageNum => {
        if (pageNum + 2 <= numPages && pageNum - 2 >= 1) {
            const current = pageNum - 2;
            setState(prevState => ({
                ...prevState,
                activeInit: current
            }));
        } else if (pageNum + 2 <= numPages && pageNum - 1 >= 1) {
            const current = pageNum - 1;
            setState(prevState => ({
                ...prevState,
                activeInit: current
            }));
        }

        handlePageChange(pageNum);
    };

    const handleClick = event => {
        const pageNum = parseInt(event.target.innerText, 10);
        setState(prevState => ({
            ...prevState,
            active: pageNum
        }));

        reset(pageNum);
    };

    const handlePrev = () => {
        const pageNum = state.active - 1;
        setState(prevState => ({
            ...prevState,
            active: pageNum
        }));

        reset(pageNum);
    };

    const handleNext = () => {
        const pageNum = state.active + 1;
        setState(prevState => ({
            ...prevState,
            active: pageNum
        }));

        reset(pageNum);
    };

    const printList = () => {
        const rows = [];
        let initNum = state.activeInit;

        const showSize = size || state.activeInit + 4;

        while (initNum <= showSize && initNum <= numPages) {
            rows.push(
                <li
                    className={`page-item${
                        state.active === initNum ? ' active' : ''
                    }`}
                    onClick={handleClick}
                    role="presentation"
                    key={initNum}
                >
                    <span className="page-link" href="#">
                        {initNum}
                    </span>
                </li>
            );

            initNum += 1;
        }
        return rows;
    };

    return (
        <nav>
            <ul className="pagination justify-content-end">
                <li className="current">
                    Page {state.active} of the {numPages}
                </li>
                <li
                    className={`page-item${
                        state.active === 1 ? ' invisible' : ''
                    }`}
                >
                    <span
                        className="page-link"
                        onClick={handlePrev}
                        onKeyDown={handlePrev}
                        role="button"
                        tabIndex="0"
                    >
                        <span aria-hidden="true">&laquo;</span>
                    </span>
                </li>

                {printList()}

                <li
                    className={`page-item${
                        state.active === numPages ? ' invisible' : ''
                    }`}
                >
                    <span
                        className="page-link"
                        onClick={handleNext}
                        onKeyDown={handleNext}
                        role="button"
                        tabIndex="0"
                    >
                        <span aria-hidden="true">&raquo;</span>
                    </span>
                </li>
            </ul>
        </nav>
    );
};

Pagination.propTypes = {
    currentPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    numPages: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    size: PropTypes.number,
    handlePageChange: PropTypes.func.isRequired
};

Pagination.defaultProps = {
    size: null
};

export default Pagination;
