import {
    LOGIN_COMPONENT,
    REGISTER_COMPONENT,
    U_PROFILE_COMPONENT,
    PROFILE_COMPONENT,
    PAYMENT_COMPONENT,
    OTP_COMPONENT,
    FORGOT_PASSWORD_COMPONENT,
    SET_PASSWORD_COMPONENT,
    SET_PROFILE_DATA
} from './CheckoutActionTypes';

const initState = {
    visibleComponent: LOGIN_COMPONENT,
    profileData: {},
    loadingProfileData: true
};

export default function checkout(state = initState, { type, ...rest } = {}) {
    switch (type) {
        case LOGIN_COMPONENT:
            return { ...state, visibleComponent: LOGIN_COMPONENT };
        case REGISTER_COMPONENT:
            return { ...state, visibleComponent: REGISTER_COMPONENT };
        case PROFILE_COMPONENT:
            return {
                ...state,
                visibleComponent: PROFILE_COMPONENT,
                profileData: rest.profileData
            };
        case U_PROFILE_COMPONENT:
            return {
                ...state,
                visibleComponent: U_PROFILE_COMPONENT,
                profileData: rest.profileData
            };
        case PAYMENT_COMPONENT:
            return { ...state, visibleComponent: PAYMENT_COMPONENT };
        case OTP_COMPONENT:
            return { ...state, visibleComponent: OTP_COMPONENT };
        case SET_PASSWORD_COMPONENT:
            return {
                ...state,
                visibleComponent: SET_PASSWORD_COMPONENT,
                email: rest.email
            };
        case FORGOT_PASSWORD_COMPONENT:
            return { ...state, visibleComponent: FORGOT_PASSWORD_COMPONENT };
        case SET_PROFILE_DATA:
            return {
                ...state,
                profileData: rest.profileData || {},
                loadingProfileData: false
            };
        default:
            return state;
    }
}
