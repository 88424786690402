import transMessagesHI from '@locales/localeHI.json';
import transMessagesEN from '@locales/localeEN.json';
import isNode from './isnode';

const fallbackCountry = 'in';

const fallbackLocale = 'en';

const supportedLangConfig = {
    [fallbackLocale]: {
        country: {
            [fallbackCountry]: {
                urlSlug: '/in/en'
            }
        },
        name: 'English',
        messages: transMessagesEN
    },
    hi: {
        country: {
            [fallbackCountry]: {
                urlSlug: '/in/hi'
            }
        },
        name: 'Hindi',
        messages: transMessagesHI
    }
};

export const config = Object.entries(supportedLangConfig).reduce(
    (obj, [locale, conf]) => {
        return {
            ...obj,
            [locale]: {
                country: conf.country,
                name: conf.name
            },
            messages: [
                ...obj.messages,
                {
                    language: locale,
                    messages: conf.messages
                }
            ]
        };
    },
    { fallbackCountry, fallbackLocale, messages: [] }
);

const trimSlases = str => {
    return str.replace(/^\/|\/$/g, '');
};

export const getLocaleFromPath = url => {
    if (!url) return fallbackLocale;

    const path = trimSlases(url.replace(/^https?;\/\//, ''));

    const pathParts = path.split('/');

    if (!pathParts || !pathParts.length || pathParts.length < 1) {
        return {
            locale: fallbackLocale,
            country: fallbackCountry
        };
    }

    const country = pathParts[0];

    const locale = pathParts[1];

    if (!config?.[locale]) {
        return {
            locale: fallbackLocale,
            country: fallbackCountry
        };
    }

    if (!config[locale]?.country?.[country]) {
        return {
            locale: fallbackLocale,
            country: fallbackCountry
        };
    }

    return {
        locale,
        country
    };
};

export const getLocaleFromName = name => {
    let locale = fallbackLocale;
    Object.entries(config).forEach(([key, value]) => {
        if (value?.name === name) {
            locale = key;
        }
    });

    return locale;
};

export const getUnLocalizedPath = path => {
    const { locale, country } = getLocaleFromPath(path);

    if (!config?.[locale]) {
        return path;
    }

    if (!config[locale]?.country?.[country]) {
        return path;
    }

    return path.replace(`${config[locale].country[country].urlSlug}`, '');
};

export const getLocalizedPath = (path, locale, country = fallbackCountry) => {
    const unLocalizedPath = getUnLocalizedPath(path);

    if (!config?.[locale]) {
        return unLocalizedPath;
    }

    if (!config[locale]?.country?.[country]) {
        return unLocalizedPath;
    }

    if (locale === fallbackLocale) {
        return unLocalizedPath;
    }

    return `${config[locale].country[country].urlSlug}/${trimSlases(
        unLocalizedPath
    )}`;
};

export const getChangedURL = (path, locale, country = fallbackCountry) => {
    if (!config?.[locale]) return path;

    if (!config[locale]?.country?.[country]) return path;

    if (isNode) return path;

    if (locale === fallbackLocale) return getUnLocalizedPath(path);

    return getLocalizedPath(path, locale, country);
};
