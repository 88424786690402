import { RENDER_COMPONENT, CLEAR_RENDER_COMPONENT } from './APIActionTypes';

const initState = {
    render: ''
};

export default function API(state = initState, action) {
    switch (action.type) {
        case RENDER_COMPONENT:
            return { ...state, render: 'render' };
        case CLEAR_RENDER_COMPONENT:
            return { ...state, render: '' };
        default:
            return state;
    }
}
