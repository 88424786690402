import {
    SHOW_NOTIFICATION,
    HIDE_NOTIFICATION
} from './NotificationActionTypes';

export function showNotification(color, message) {
    const msg = Array.isArray(message) ? message[0] : message;

    return {
        type: SHOW_NOTIFICATION,
        color,
        message: msg
    };
}

export function hideNotification() {
    return {
        type: HIDE_NOTIFICATION
    };
}
