import TYPES from './types';

const envState = {
    SALE_ON: false,
    ADS_ACTIVE: true,
    FB_ID: process.env.FB_ID || '893935084396729',
    WEB_URL: process.env.WEB_URL || 'https://orangebookvalue.com',
    GOOGLE_ID: process.env.GOOGLE_ID || '138951947721-2uu10c8q3gn0r4npdevv07tjjma8vq9a.apps.googleusercontent.com',
    PUBLIC_PATH: process.env.PUBLIC_PATH || 'https://orangebookvalue.com/obvassets/'
};

const initState = (state = envState, { type, payload } = {}) => {
    switch (type) {
        case TYPES.GET_CONFIG:
            return {
                ...state,
                ...payload
            };

        default:
            return state;
    }
};

export default initState;
