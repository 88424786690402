import {
    NAVIGATION_SHOW,
    NAVIGATION_HIDE,
    NAVIGATION_ENTERPRISE,
    UPDATE_LOCATION,
    GLOBAL_DATA
} from './HeaderActionTypes';

const initialState = {
    navigation: 1,
    selectedLocation: '',
    globalData: {
        categories: {
            1: 'Car',
            2: 'Bike/Motorcycle',
            3: 'Scooter',
            4: 'Plane',
            5: 'Bicycle',
            7: 'Taxi',
            21: 'Truck',
            31: 'Bus',
            41: 'Tractor',
            51: 'Electric Car',
            61: 'Electric Scooter',
            111: 'Electric Bike'
        }
    }
};

export default function header(
    state = initialState,
    { type, selectedLocation, globalData } = {}
) {
    switch (type) {
        case NAVIGATION_HIDE:
            return { ...state, navigation: 0 };
        case NAVIGATION_SHOW:
            return { ...state, navigation: 1 };
        case NAVIGATION_ENTERPRISE:
            return { ...state, navigation: 2 };
        case UPDATE_LOCATION:
            return { ...state, selectedLocation };
        case GLOBAL_DATA:
            return {
                ...state,
                globalData: {
                    ...state.globalData,
                    ...globalData
                }
            };
        default:
            return state;
    }
}
