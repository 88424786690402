import React from 'react';
import { hydrate, render } from 'react-dom';
import { loadableReady } from '@loadable/component';
import { createFrontloadState } from 'react-frontload';
import { BrowserRouter } from 'react-router-dom';

import App from '@components/App';
import configureStore from '@redux/configureStore';

import 'bootstrap';

const frontloadState = createFrontloadState.client({
    context: { api: null },
    serverRenderedData: window.__UNIVERSSR_FRONTLOAD_DATA__
});

const preloadedState = window.__UNIVERSSR_PRELOADED_STATE__;

const store = configureStore(preloadedState);

// this will change public path for client side
// based on env values of current env
// eslint-disable-next-line camelcase
__webpack_public_path__ = `${preloadedState.initState.PUBLIC_PATH.replace(
    /(\/+$)/g,
    ''
)}/${BROWSERSLIST_ENV}/`;

const renderMethod = import.meta.webpackHot ? render : hydrate;

loadableReady(() => {
    renderMethod(
        <BrowserRouter>
            <App frontloadState={frontloadState} reduxStore={store} />
        </BrowserRouter>,
        document.getElementById('root')
    );
});

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
}
