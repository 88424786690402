import thunk from 'redux-thunk';
import { compose, createStore, applyMiddleware } from 'redux';

import { isDev, isNode } from '@config/index';
import createRootReducer from '.';

export default function configureStore({ ...initialState }) {
    const middleware = [thunk];

    if (isDev) {
        const logger = {};

        // Redux logger will only work for client side
        Object.entries(console).forEach(([method, fn]) => {
            if (typeof fn === 'function') {
                logger[method] = isNode ? () => {} : fn.bind(console);
            }
        });

        middleware.push(
            reduxLogger.createLogger({
                duration: true,
                logger
            })
        );
    }

    // check if redux devtools extension compose available
    // apply for development environment only
    const withReduxDevtools =
        isDev &&
        typeof window !== 'undefined' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

    // make compose enhancers
    const composeEnhancers = withReduxDevtools
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
              /* specify extension’s options, if any */
          })
        : compose;

    const enhancer = composeEnhancers(applyMiddleware(...middleware));
    const store = createStore(createRootReducer(), initialState, enhancer);

    if (import.meta.webpackHot) {
        import.meta.webpackHot.accept('./index', () => {
            store.replaceReducer(createRootReducer());
        });
    }

    return store;
}
