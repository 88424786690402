import React from 'react';
import PropTypes from 'prop-types';

const AccessDenied = ({ message }) => {
    return <div>{message}</div>;
};

AccessDenied.propTypes = {
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired
};

export default AccessDenied;
