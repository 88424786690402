import {
  DASHBOARD_COMPONENT
} from './EnterpriseActionTypes'

const initState = {
  visibleComponent: DASHBOARD_COMPONENT
}

export default function enterprise(state = initState, action) {
  switch (action.type) {
    case DASHBOARD_COMPONENT:
      return Object.assign({}, state, {
        visibleComponent: DASHBOARD_COMPONENT
      });
    default:
      return state
  }
}
