import { SHOW_BAR, HIDE_BAR } from './LoaderActionTypes';

const initState = {
    show: true
};

export default function loader(state = initState, action) {
    switch (action.type) {
        case SHOW_BAR:
            return Object.assign({}, state, {
                count: (state.show = true)
            });
        case HIDE_BAR:
            return Object.assign({}, state, {
                count: (state.show = false)
            });
        default:
            return state;
    }
}
