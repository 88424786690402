import { useSelector } from 'react-redux';

const useAuth = () => {
    const { profileData = {}, loadingProfileData = true } = useSelector(
        state => state.checkout
    );

    return {
        loading: loadingProfileData,
        loggedIn: !!profileData?.account_id,
        accountType: profileData?.account_type,
        isEnterprise: profileData?.account_type === 'enterprise',
        isDash: !!profileData?.isDash
    };
};

export default useAuth;
