import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate, Outlet } from 'react-router-dom';

import useAuth from '@components/Hooks/useAuth';
import { Loader } from '@components/index';

export const Types = {
    ENTERPRISE: 'enterprise',
    USER: 'user'
};

const EnterpriseRoute = ({ children }) => {
    const { loggedIn, isEnterprise } = useAuth();

    const navigate = useNavigate();

    React.useEffect(() => {
        if (isEnterprise) return null;
        if (loggedIn) return window.location.assign('/account/info');
        return navigate('/enterprise-login');
    }, [loggedIn, isEnterprise, navigate]);

    return children || <Outlet />;
};

EnterpriseRoute.propTypes = {
    children: PropTypes.node
};

EnterpriseRoute.defaultProps = {
    children: null
};

const UserRoute = ({ children }) => {
    const { loggedIn } = useAuth();

    const navigate = useNavigate();

    React.useEffect(() => {
        if (!loggedIn) navigate('/login', { replace: true });
    }, [loggedIn, navigate]);

    return children || <Outlet />;
};

UserRoute.propTypes = {
    children: PropTypes.node
};

UserRoute.defaultProps = {
    children: null
};

export const ProtectedRoute = ({ type, children }) => {
    const { loading } = useAuth();

    if (loading) {
        return <Loader />;
    }

    switch (type) {
        case Types.ENTERPRISE:
            return <EnterpriseRoute>{children}</EnterpriseRoute>;

        case Types.USER:
        default:
            return children || <Outlet />;
    }
};

ProtectedRoute.propTypes = {
    type: PropTypes.string,
    children: PropTypes.node
};

ProtectedRoute.defaultProps = {
    type: 'default',
    children: null
};
