import React from 'react';
import { useRoutes, Navigate } from 'react-router-dom';

import { Layout } from '@root/app/pages/base';
import { RouteTypes, ProtectedRoute } from '@components/HOC';

import Home from '@modules/home';
import Checkout from '@modules/checkout';
import Enterprise from '@modules/enterprise';
import EnterpriseLogin from '@modules/auth/login';

import Dashboard from '@modules/enterprise/Dashboard';

import Residual from '@modules/enterprise/Residual';

import Pricing from '@modules/enterprise/Pricing';

import DealerDashboard from '@modules/enterprise/Dealerdash';

import Bulk from '@modules/enterprise/Bulk';
import BulkForm from '@modules/enterprise/Bulk/Form';
import BulkQuery from '@modules/enterprise/Bulk/Query';
import BulkQueryBucket from '@modules/enterprise/Bulk/QueryBucket';

import User from '@modules/enterprise/User';
import UserForm from '@modules/enterprise/User/Form';

import Download from '@modules/enterprise/Download';

import API from '@modules/enterprise/API';

import Widget from '@modules/enterprise/Widget';

import Account from '@modules/enterprise/Account';

import CheckPrice from '@modules/enterprise/CheckPrice';

import DealerManagement from '@modules/enterprise/Dealer';
import DealerManagementList from '@modules/enterprise/Dealer/List';

import Benchmarking from '@modules/enterprise/Benchmarking';

import EmergingTrends from '@modules/enterprise/EmergingTrends';

import ConsumerPreference from '@modules/enterprise/Consumer-preference';

import BuySellPreference from '@modules/enterprise/BuySell-preference';

import ValueAddedTools from '@modules/enterprise/ValueAddedTools';

import ApiLog from '@modules/enterprise/API/Logs';

import EnterpriseInfo from '@modules/enterprise/Info/Enterpriseinfo';
import PremiumContainer from '@modules/valuation';

const homeConfig = {
    element: <Home />,
    hideFooterAd: true
};

const children = [
    {
        index: true,
        menu: 'Home',
        ...homeConfig
    },
    {
        path: 'exchange',
        menu: 'Exchange',
        ...homeConfig
    },
    {
        path: 'new',
        menu: 'New',
        ...homeConfig
    },
    {
        path: 'future',
        menu: 'Future',
        ...homeConfig
    },
    {
        path: 'in/hi',
        menu: 'Home',
        ...homeConfig,
        children: [
            {
                path: 'new',
                ...homeConfig
            },
            {
                path: 'exchange',
                ...homeConfig
            },
            {
                path: 'future',
                ...homeConfig
            }
        ]
    },
    {
        path: 'checkout',
        menu: 'Checkout',
        element: <Checkout />
    },
    {
        path: 'enterprise-login',
        menu: 'Enterprise Login',
        element: <EnterpriseLogin />
    },
    {
        element: <ProtectedRoute type={RouteTypes.ENTERPRISE} />,
        children: [
            {
                path: 'enterprise',
                menu: 'Enterprise',
                element: <Enterprise />,
                children: [
                    {
                        path: 'dashboard',
                        title: 'Welcome',
                        element: <Dashboard />
                    },
                    {
                        path: 'residual-compare',
                        title: 'Residual Value Comparison',
                        element: <Residual />
                    },
                    {
                        path: 'pricing-value',
                        title: 'OBV Pricing Value',
                        element: <Pricing />
                    },
                    {
                        path: 'dealer-dashboard',
                        title: 'Dealer Dashboard',
                        element: <DealerDashboard />
                    },
                    {
                        path: 'bulk-upload',
                        title: 'Bulk Pricing',
                        element: <Bulk />,
                        children: [
                            {
                                index: true,
                                title: 'Bulk Pricing',
                                element: <BulkForm />
                            },
                            {
                                path: 'query',
                                title: 'Bulk Pricing Query',
                                element: <BulkQuery />
                            },
                            {
                                path: 'query-bulk/:requestID',
                                title: 'Bulk Pricing Query Details',
                                element: <BulkQueryBucket />
                            }
                        ]
                    },
                    {
                        path: 'user-manage',
                        title: 'User Management',
                        element: <User />,
                        children: [
                            {
                                index: true,
                                title: 'Create Dealer',
                                element: <UserForm />
                            },
                            {
                                path: ':dealerID',
                                title: 'Edit Dealer',
                                element: <UserForm />
                            }
                        ]
                    },
                    {
                        path: 'download',
                        title: 'Your Download',
                        element: <Download />
                    },
                    {
                        path: 'api',
                        title: 'API Integration',
                        element: <API />
                    },
                    {
                        path: 'widget',
                        title: 'Widget Management',
                        element: <Widget />
                    },
                    {
                        path: 'account',
                        title: 'My Account',
                        element: <Account />
                    },
                    {
                        path: 'check-price',
                        title: 'Check Price',
                        element: <CheckPrice />
                    },
                    {
                        path: 'dealer-management',
                        title: 'Dealer Management',
                        element: <DealerManagement />,
                        children: [
                            {
                                index: true,
                                element: <DealerManagementList />
                            }
                        ]
                    },
                    {
                        path: 'competitor-benchmarking',
                        title: 'Competitor Benchmarking',
                        element: <Benchmarking />
                    },
                    {
                        path: 'emerging-trends',
                        title: 'Emerging Trends',
                        element: <EmergingTrends />
                    },
                    {
                        path: 'consumer-preference',
                        title: 'Market Overview',
                        element: <ConsumerPreference />
                    },
                    {
                        path: 'buy-sell-preference',
                        title: 'Buy/Sell Preferences',
                        element: <BuySellPreference />
                    },
                    {
                        path: 'value-added-tools',
                        title: 'TCO, Ratings, On-road, Showroom Price',
                        element: <ValueAddedTools />
                    },
                    {
                        path: 'apilogs/:logID',
                        title: 'API Logs',
                        element: <ApiLog />
                    },
                    {
                        path: 'enterpriseinfo',
                        title: 'Enterprise Info',
                        element: <EnterpriseInfo />
                    }
                ]
            }
        ]
    },
    {
        path: '/valuation',
        title: 'Valuation',
        element: <PremiumContainer />
    },
    {
        path: '*',
        element: <Navigate to="/" replace />
    }
];

export const routes = [
    {
        element: <Layout />,
        path: '/',
        children
    }
];

const Router = () => useRoutes(routes);

export default Router;
