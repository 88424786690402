// import helmet from './helmet';
import syspath from './syspath';

import isNode from './isnode';

const isDev = process.env.NODE_ENV === 'development';
const DEV = isDev;
const isTest = process.env.NODE_ENV === 'test';

const cookieDomain = '.orangebookvalue.com';
export {
    config as LocaleConfig,
    getLocaleFromPath,
    getLocaleFromName,
    getChangedURL as changedLocationURL,
    getLocalizedPath,
    getUnLocalizedPath
} from './locale';

export { syspath, isNode, isDev, DEV, isTest, cookieDomain };
