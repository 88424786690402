import {
    SHOW_NOTIFICATION,
    HIDE_NOTIFICATION
} from './NotificationActionTypes';

const initState = {
    show: false,
    color: 'error',
    message: 'text'
};

export default function notification(state = initState, action) {
    switch (action.type) {
        case SHOW_NOTIFICATION:
            return {
                ...state,
                show: true,
                color: action.color,
                message: action.message
            };
        case HIDE_NOTIFICATION:
            return { ...state, show: false, color: 'error', message: '' };
        default:
            return state;
    }
}
