import {
    LOGIN_COMPONENT,
    FORGOT_PASSWORD,
    SET_PASSWORD
} from './UserLoginActionTypes';

const InitState = {
    visibleComponent: LOGIN_COMPONENT
};

export default function userLogin(state = InitState, { type, email } = {}) {
    switch (type) {
        case LOGIN_COMPONENT:
            return {
                ...state,
                visibleComponent: LOGIN_COMPONENT,
                email: undefined
            };
        case FORGOT_PASSWORD:
            return {
                ...state,
                visibleComponent: FORGOT_PASSWORD,
                email: undefined
            };
        case SET_PASSWORD:
            return {
                ...state,
                visibleComponent: SET_PASSWORD,
                email
            };
        default:
            return state;
    }
}
