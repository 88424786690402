export const OBV_DOMAIN = 'obv';
export const DROOM_DOMAIN = 'droom';

export const DEVICE = {
    MOBILE: 'mobile',
    COMPUTER: 'computer'
};
export const ORIENTATION = {
    DESKTOP: 'desktop',
    PORTRAIT: 'portrait',
    LANDSCAPE: 'landscape'
};
export const COMPUTER_MIN_WIDTH = 1024;
