import MODAL_CLICK from './HomeActionsTypes';

const initState = {
    vehicle: true,
    mobile: false,
    modalClick: 0,
    modalVideo: ''
};

export default function home(state = initState, { type } = {}) {
    switch (type) {
        case MODAL_CLICK:
            return { ...state, modalClick: 1 };
        default:
            return state;
    }
}
