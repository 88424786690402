import { combineReducers } from 'redux';
import header from '@app/layouts/header/HeaderReducer';
import loader from '@components/Loader/LoaderReducer';
import modal from '@components/Modal/ModalReducer';
import notification from '@components/Notification/NotificationReducer';
import home from '@modules/home/HomeReducer';
import checkout from '@modules/checkout/CheckoutReducer';
import enterprise from '@modules/enterprise/EnterpriseReducer';
import enterpriseAPIRed from '@modules/enterprise/API/APIReducer';
import userLogin from '@components/auth/UserLoginReducer';
import detect from '@redux/detect/reducer';
import initState from '@redux/initState/reducer';

// add more reducers here if we have any
export default function createRootReducer() {
    return combineReducers({
        initState,
        loader,
        modal,
        notification,
        header,
        home,
        checkout,
        enterprise,
        enterpriseAPIRed,
        userLogin,
        detect
    });
}
