import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PostData } from '@components/auth/fetchData';
import Pagination from '@components/Pagination/Pagination';
import { showNotification } from '@components/Notification/NotificationActions';
import AccessDenied from '../partials/AccessDenied';

const EnterpriseInfo = () => {
    const [state, setState] = React.useState({
        api: [],
        loading: true,
        showAccessDenied: undefined
    });

    const WEB_URL = useSelector(global => global.initState.WEB_URL);

    const apiURL = `${WEB_URL}/enterprise/entInfo`;

    const dispatch = useDispatch();

    const postURL = React.useCallback(
        ({ url, data = {} }) => {
            PostData(url, data).then(result => {
                const responseJson = result;
                if (responseJson.code === 'success') {
                    setState({
                        api: responseJson.data,
                        currentPage: 1,
                        numPages: 1,
                        recordsPerPage: 12,
                        showAccessDenied: false
                    });
                } else if (responseJson.code === 'failed') {
                    setState({
                        errors: responseJson.errors,
                        showAccessDenied: true
                    });
                } else dispatch(showNotification('error', responseJson.errors));
            });
        },
        [dispatch]
    );

    const handlePageChange = page => {
        const data = {
            page: parseInt(page, 10)
        };
        postURL({ url: apiURL, data });
    };

    React.useEffect(() => {
        postURL({ url: apiURL });
    }, [postURL, apiURL]);

    if (state.loading) return <div>Loading...</div>;

    return (
        <div className="bulk block">
            <div className="header text-left">
                <strong>Enterprise Request Info</strong>
            </div>
            {!state.showAccessDenied ? (
                <div>
                    <div className="content">
                        <div className="container-fluid">
                            <div className="table">
                                <div className="row">
                                    <div className="col-xl-1 col-lg-1 col-sm-1">
                                        S.No.
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-sm-2">
                                        <strong>Name</strong>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-sm-3">
                                        <strong>Email</strong>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-sm-2">
                                        <strong>Mobile</strong>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-sm-2">
                                        <strong>Company Url</strong>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-sm-2">
                                        <strong>Created Date</strong>
                                    </div>
                                </div>

                                {state.api.map((item, i) => (
                                    <div
                                        className="row"
                                        key={`${item.name}-${item.company_url}-${item.email}-key`}
                                        value={i}
                                        role="presentation"
                                    >
                                        <div
                                            className="col-xl-1 col-lg-1 col-sm-1"
                                            value={i}
                                        >
                                            {i +
                                                1 +
                                                (state.currentPage * 10 - 10)}
                                        </div>
                                        <div
                                            className="col-xl-2 col-lg-2 col-sm-2"
                                            value={i}
                                        >
                                            {item.name}
                                        </div>
                                        <div
                                            className="col-xl-3 col-lg-3 col-sm-3"
                                            value={i}
                                        >
                                            {item.email}
                                        </div>
                                        <div
                                            className="col-xl-2 col-lg-2 col-sm-2"
                                            value={i}
                                        >
                                            {item.mobile_number}
                                        </div>
                                        <div
                                            className="col-xl-2 col-lg-2 col-sm-2"
                                            value={i}
                                        >
                                            {item.company_url}
                                        </div>
                                        <div className="col-xl-2 col-lg-2 col-sm-2">
                                            {item.created_at}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="footer">
                        <Pagination
                            currentPage={state.currentPage}
                            numPages={state.numPages}
                            handlePageChange={handlePageChange}
                        />
                    </div>
                </div>
            ) : (
                <AccessDenied message={state.errors} />
            )}
        </div>
    );
};

export default EnterpriseInfo;
